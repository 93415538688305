header {
    /*margin-bottom: 00px;*/
    /*box-shadow: 0px 7px 15px 0px rgb(34 60 80 / 60%);*/
}

#breadcrumb {
    background: #e3e3e3;
    margin-bottom: 10px;
    box-shadow: 0px 4px 6px 0px rgb(183 183 183 / 60%);
}

.breadcrumb {
    margin-bottom: 3px !important;
}

.dropdown-menu[data-bs-popper] {
    margin-top: 0 !important;
}
