.num {
    display: inline-block !important;
    width: 100px !important;
}

.dateInput {
    display: inline-block !important;
    width: 160px !important;
}

.PrintPreview {
    width: 770px;
}

.wrapperPrint {
    padding: 5px;
    background-color: #4f5050;
}

.templateSvidPlus {
    background-image: url("/public/SvidPlus.png");
    background-repeat: no-repeat;
    background-size: 760px;
    /*padding: 81px 50px;*/
}

.templateDiploma {
    background-image: url("/public/Diploma.png");
    background-repeat: no-repeat;
    background-size: 1010px;
    /*padding-top: 39px;*/
    height: 788px;
}

.templateDiplomaPlusFront {
    background-image: url("/public/DiplomaPlusFront.png");
    background-repeat: no-repeat;
    background-size: 760px;
    padding-top: 60px;
    height: 1071px;
}

.templateDiplomaPlusBack {
    background-image: url("/public/DiplomaPlusBack.png");
    background-repeat: no-repeat;
    background-size: 760px;
    padding-top: 157px;
    height: 1071px;
    padding-left: 26px;
}

.body2 div {
    background-color: #fff;
}

.fixSved {
    width: 770px;
}

.fixDiploma {
    width: 1096px;
}

@media print {
    @page {
        margin: 0;
    }

    body {
        margin: 1.6cm;
    }

    .page-break {
        page-break-before: always;
    }

    .hideColor {
        display: none;
    }

    /* page-break-after works, as well */
}

@media print {
    .wrapperPrint {
        margin-top: 200px;
    }

    .rotatePrint {
        margin-top: 200px;
        transform: rotate(90deg);
    }

    .diplomaBack {
        padding-top: 115px;
        padding-left: 35px;
    }

    .page-break {
        page-break-after: always;
    }
}

/*диплом бэк*/

.page-break {
    page-break-after: always;
}

p {
    margin: 0pt 0pt 8pt;
}

.wrapperPrint li,
table {
    margin-top: 0pt;
    margin-bottom: 8pt;
}

.Footer {
    margin-bottom: 0pt;
    line-height: normal;
    font-size: 11pt;
}

.HTMLPreformatted {
    margin-bottom: 0pt;
    line-height: normal;
    font-family: "Courier New";
    font-size: 10pt;
}

.Header {
    margin-bottom: 0pt;
    line-height: normal;
    font-size: 11pt;
}

.ListParagraph {
    margin-left: 36pt;
    margin-bottom: 8pt;
    line-height: 108%;
    font-size: 11pt;
}

span.HTML {
    font-family: "Courier New";
    font-size: 10pt;
}

#content2 {
    width: 828px;
}
